<template>
  <b-sidebar
    id="add-new-job-sidebar"
    :visible="isAddNewJobSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="
      () => {
        resetForm();
        resetFields();
      }
    "
    @change="(val) => $emit('update:is-add-new-job-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{
            actionType == 0
              ? $i18n.t("Add New Job")
              : actionType == 1
              ? $i18n.t("Update Job")
              : $i18n.t("Delete Job")
          }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <b-form-group :label="$i18n.t('Device')">
            <v-select
              id="machineId"
              v-model="device"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="devices"
              :reduce="(option) => option.id"
              class="select-size-lg"
              :disabled="actionType == 2"
            >
              <template slot="selected-option" slot-scope="option">
                <span>{{ option.name }}</span>
              </template>
              <template #option="{ id, name }">
                <span> {{ name }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Priority')">
            <v-select
              id="prioriyId"
              v-model="priority"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="priorities"
              class="select-size-lg"
              :disabled="actionType == 2"
            >
              <template #option="{ id, name }">
                <span> {{ name }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Product')">
            <v-select
              id="productId"
              v-model="productId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="products"
              :reduce="(option) => option.id"
              class="select-size-lg"
              :disabled="actionType == 2"
            >
              <template #option="{ id, name }">
                <span> {{ name }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group :label="$i18n.t('Mould')">
            <v-select
              id="mouldId"
              v-model="mouldId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="code"
              :options="moulds"
              :reduce="(option) => option.id"
              class="select-size-lg"
              :disabled="actionType == 2"
            >
              <template #option="{ id, code }">
                <span> {{ code }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Description')">
            <b-form-input
              id="jobDescription"
              v-model="description"
              :disabled="actionType == 2"
              autofocus
              trim
              :placeholder="$i18n.t('Description')"
            />
          </b-form-group>
          <b-form-group :label="$i18n.t('Category')">
            <v-select
              id="category"
              v-model="category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="categories"
              class="select-size-lg"
              :disabled="actionType == 2"
            >
              <template #option="{ id, name }">
                <span> {{ name }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group :label="$i18n.t('Pieces')">
            <b-form-input
              id="pieces"
              v-model="pieces"
              :disabled="actionType == 2"
              autofocus
              trim
              :placeholder="$i18n.t('Pieces')"
            />
          </b-form-group>
          <validation-provider
            #default="validationContext"
            name="Target Finish Date"
          >
            <!-- rules="required" -->
            <b-form-group :label="$i18n.t('Target FinishDate')">
              <flat-pickr
                id="targetFinishDate"
                v-model="targetFinishDate"
                class="form-control"
                :config="{
                  enableTime: true,
                  locale: $i18n.t('locale_datePickr'),
                  time_24hr: true,
                  dateFormat: 'Y-m-d H:i',
                }"
                :placeholder="$i18n.t('Target FinishDate')"
              />
              <b-form-invalid-feedback
                style="color: red"
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}*
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group :label="$i18n.t('Responsible Name')">
            <v-select
              id="responsibleUserId"
              v-model="responsibleUserId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="responsible"
              :reduce="(option) => option.id"
              class="select-size-lg"
              :disabled="actionType == 2"
            >
              <template slot="selected-option" slot-scope="option">
                <span>{{ option.name }}</span>
              </template>
              <template #option="{ id, name }">
                <span> {{ name }}</span
                ><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
            label-for="RequestDate"
            :label="$i18n.t('Request Date')"
          >
            <!-- <b-form-input
          id="date"
          v-model="blankRequestData.Date"
        /> -->
            <flat-pickr
              id="RequestDate"
              v-model="requestDate"
              class="form-control"
              :config="{
                enableTime: true,
                locale: $i18n.t('locale_datePickr'),
                time_24hr: true,
              }"
              :placeholder="$i18n.t('Request Date')"
            />
            <!-- <b-form-input id="start-date"
                            v-model="currentWorkCenterShift.startDate"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            type="date"
                            step="1"
                            :disabled="actionType == 2"
                            placeholder="Start Date" /> -->
          </b-form-group>

          <b-form-group :label="$i18n.t('Requester User')">
            <b-form-input
              id="requesterUser"
              v-model="requesterUser"
              :disabled="actionType == 2"
              autofocus
              trim
              :placeholder="$i18n.t('Requester User')"
            />
          </b-form-group>
          <b-form-group :label="$i18n.t('Request Description')">
            <b-form-input
              id="requestDescription"
              v-model="requestDescription"
              :disabled="actionType == 2"
              autofocus
              trim
              :placeholder="$i18n.t('Request Description')"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-if="actionType != 2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ actionType == 0 ? $i18n.t("Add") : $i18n.t("Update") }}
            </b-button>
            <b-button
              v-if="Job != null && Job.id != null && actionType == 2"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              variant="outline-danger"
              @click="deleteJob"
            >
              Cancel
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $i18n.t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, url } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
//import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewJobSidebarActive",
    event: "update:is-add-new-job-sidebar-active",
  },
  props: {
    isAddNewJobSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
    selectedJob: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      required,
      device: 0,
      priority: 0,
      productId: 0,
      mouldId: 0,
      description: "",
      category: "",
      pieces: "",
      targetFinishDate: new Date(),
      responsibleUserId: 0,
      requestDate: null,
      requesterUser: "",
      requestDescription: "",
      currentIsActive: true,
      id: 0,
      devices: [],
      priorities: [],
      products: [],
      moulds: [],
      categories: [],
      responsible: [],
      Job: {
        device: null,
        priority: null,
        productId: null,
        mouldId: null,
        description: null,
        category: null,
        pieces: null,
        targetFinishDate: null,
        responsibleUserId: null,
        requestDate: null,
        requesterUser: null,
        requester: null,
        requestDescription: null,
      },
      changeWorkCenterActive: false,
      value: null,
      options: null,
      isInitialEdit: false,
    };
  },
  computed: {
    ...mapGetters({ setFieldsToValues: "pamis-job/GetJobFields" }),
  },
  watch: {
    selectedJob(val) {
      if (val?.device) {
        this.id = val.id;
        this.device = this.parseDeviceName(val.device);
        this.productId = val.product.id;
        this.mouldId = val.mould?.id;
        this.category = val.category;
        this.pieces = val.pieces;
        this.priority = val.priority;
        this.description = val.description;
        this.targetFinishDate = val.targetFinishDate;
        this.responsibleUserId = val.responsibleUser.id;
        this.requesterUser = val.requesterUser;
        this.requestDescription = val.requestDescription;
        this.requestDate = val.requestDate;
      }
    },
    setFieldsToValues(val) {
      if (val != null) {
        this.categories = val.categories;
        this.devices = val.devices;
        this.priorities = val.priority;
        this.products = val.products;
        this.responsible = val.responsibleUsers;
        this.responsible.forEach((e) => {
          e.name = `${e.name} ${e.surName}`;
        });
      }
    },
    productId(val) {
      const foundProduct = this.products.find((e) => e.id == val);
      if (foundProduct) {
        this.moulds = foundProduct.moulds.map((x) => ({
          id: x.id,
          code: x.code,
        }));
      }
    },
  },
  mounted() {},
  methods: {
    resetFields() {
      this.device = 0;
      this.priority = 0;
      this.productId = 0;
      this.mouldId = 0;
      this.description = "";
      this.category = "";
      this.pieces = "";
      this.targetFinishDate = null;
      this.responsibleUserId = 0;
      this.requestDate = null;
      this.requesterUser = "";
      this.requestDescription = "";
    },
    deleteJob() {
      this.$store
        .dispatch("pamis-job/deleteJob", { id: this.Job.id })
        .then((result) => {
          if (result) {
            //this.$emit("refetch-data");
            this.$emit("update:is-add-new-job-sidebar-active", false);
            this.notification();
          }
        });
    },
    submitForm() {
      const date = new Date();
      let targetFinishDate = new Date(this.targetFinishDate);
      targetFinishDate = new Date(
        targetFinishDate.getTime() - date.getTimezoneOffset() * 60 * 1000
      );
      let requestDate = new Date(this.requestDate);
      requestDate = new Date(
        requestDate.getTime() - date.getTimezoneOffset() * 60 * 1000
      );
      this.Job = {
        status: this.selectedJob?.status,
        id: this.id,
        deviceId: this.device,
        priority: this.priority,
        productId: this.productId,
        mouldId: this.mouldId != 0 ? this.mouldId : null,
        description: this.description,
        category: this.category,
        pieces: this.pieces,
        targetFinishDate: targetFinishDate,
        responsibleUserId: this.responsibleUserId ?? 0,
        requestDate: requestDate,
        requesterUser: this.requesterUser,
        requestDescription: this.requestDescription,
      };
      if (
        !this.device ||
        !this.responsibleUserId ||
        !this.productId ||
        !this.description ||
        !this.category ||
        this.device == 0 ||
        this.responsibleUserId == 0 ||
        this.productId == 0 ||
        this.category.length < 1 ||
        this.description.length < 1
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Please check 'Device', 'Description', 'Category', 'Product', 'Responsible' fields are filled",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
      let process = "";
      if (this.actionType > 0) {
        process = "pamis-job/UpdateJob";
      } else {
        process = "pamis-job/CreateJob";
      }
      // this.Job.isActive = this.currentIsActive
      this.$store.dispatch(process, this.Job).then(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-add-new-job-sidebar-active", false);
        this.notification();
      });
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Process Successful",
          icon: "BellIcon",
        },
      });
    },
    parseDeviceName(deviceName) {
      let idString = "";
      let isId = false;
      for (let charIndex in deviceName) {
        if (deviceName[charIndex] === ")") break;
        if (isId) {
          idString += deviceName[charIndex];
        }
        if (deviceName[charIndex] === "(") {
          isId = true;
        }
      }
      return parseInt(idString);
    },
  },

  setup(props, { emit }) {
    const blankJobData = {
      machine: null,
      priority: null,
      productId: null,
      mouldId: null,
      description: null,
      category: null,
      pieces: null,
      targetFinishDate: new Date(),
      responsibleUserId: null,
      requestDate: new Date(),
      requester: null,
      requesterDescription: null,
      isActive: false,
    };
    // const jobFields = computed(() => store.getters['pamis-job/GetJobFields'])
    const jobData = ref(JSON.parse(JSON.stringify(blankJobData)));
    const resetJobData = () => {
      jobData.value = JSON.parse(JSON.stringify(blankJobData));
      //emit("refetch-data");
      emit("update:is-add-new-job-sidebar-active", false);
    };
    /*
    const onSubmit = () => {
      store.dispatch("pamis-job/addJob", Job).then(() => {});
    };
    */

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetJobData);

    return {
      //  jobData,
      //  onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      // jobFields,
    };
  },
};
</script>

  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-job-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
