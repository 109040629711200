var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-job-sidebar","visible":_vm.isAddNewJobSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":function () {
      _vm.resetForm();
      _vm.resetFields();
    },"change":function (val) { return _vm.$emit('update:is-add-new-job-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.actionType == 0 ? _vm.$i18n.t("Add New Job") : _vm.actionType == 1 ? _vm.$i18n.t("Update Job") : _vm.$i18n.t("Delete Job"))+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Device')}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"machineId","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.devices,"reduce":function (option) { return option.id; },"disabled":_vm.actionType == 2},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.name))])]}},{key:"option",fn:function(ref){
    var id = ref.id;
    var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.device),callback:function ($$v) {_vm.device=$$v},expression:"device"}})],1),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Priority')}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"prioriyId","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.priorities,"disabled":_vm.actionType == 2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
    var id = ref.id;
    var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}})],1),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Product')}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"productId","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.products,"reduce":function (option) { return option.id; },"disabled":_vm.actionType == 2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
    var id = ref.id;
    var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.productId),callback:function ($$v) {_vm.productId=$$v},expression:"productId"}})],1),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Mould')}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"mouldId","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"code","options":_vm.moulds,"reduce":function (option) { return option.id; },"disabled":_vm.actionType == 2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
    var id = ref.id;
    var code = ref.code;
return [_c('span',[_vm._v(" "+_vm._s(code))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.mouldId),callback:function ($$v) {_vm.mouldId=$$v},expression:"mouldId"}})],1),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Description')}},[_c('b-form-input',{attrs:{"id":"jobDescription","disabled":_vm.actionType == 2,"autofocus":"","trim":"","placeholder":_vm.$i18n.t('Description')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Category')}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.categories,"disabled":_vm.actionType == 2},scopedSlots:_vm._u([{key:"option",fn:function(ref){
    var id = ref.id;
    var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Pieces')}},[_c('b-form-input',{attrs:{"id":"pieces","disabled":_vm.actionType == 2,"autofocus":"","trim":"","placeholder":_vm.$i18n.t('Pieces')},model:{value:(_vm.pieces),callback:function ($$v) {_vm.pieces=$$v},expression:"pieces"}})],1),_c('validation-provider',{attrs:{"name":"Target Finish Date"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Target FinishDate')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"targetFinishDate","config":{
                enableTime: true,
                locale: _vm.$i18n.t('locale_datePickr'),
                time_24hr: true,
                dateFormat: 'Y-m-d H:i',
              },"placeholder":_vm.$i18n.t('Target FinishDate')},model:{value:(_vm.targetFinishDate),callback:function ($$v) {_vm.targetFinishDate=$$v},expression:"targetFinishDate"}}),_c('b-form-invalid-feedback',{staticStyle:{"color":"red"},attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+"* ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Responsible Name')}},[_c('v-select',{staticClass:"select-size-lg",attrs:{"id":"responsibleUserId","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.responsible,"reduce":function (option) { return option.id; },"disabled":_vm.actionType == 2},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.name))])]}},{key:"option",fn:function(ref){
              var id = ref.id;
              var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))]),_c('br'),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(id)+" ")])]}}],null,true),model:{value:(_vm.responsibleUserId),callback:function ($$v) {_vm.responsibleUserId=$$v},expression:"responsibleUserId"}})],1),_c('b-form-group',{attrs:{"label-for":"RequestDate","label":_vm.$i18n.t('Request Date')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"RequestDate","config":{
              enableTime: true,
              locale: _vm.$i18n.t('locale_datePickr'),
              time_24hr: true,
            },"placeholder":_vm.$i18n.t('Request Date')},model:{value:(_vm.requestDate),callback:function ($$v) {_vm.requestDate=$$v},expression:"requestDate"}})],1),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Requester User')}},[_c('b-form-input',{attrs:{"id":"requesterUser","disabled":_vm.actionType == 2,"autofocus":"","trim":"","placeholder":_vm.$i18n.t('Requester User')},model:{value:(_vm.requesterUser),callback:function ($$v) {_vm.requesterUser=$$v},expression:"requesterUser"}})],1),_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Request Description')}},[_c('b-form-input',{attrs:{"id":"requestDescription","disabled":_vm.actionType == 2,"autofocus":"","trim":"","placeholder":_vm.$i18n.t('Request Description')},model:{value:(_vm.requestDescription),callback:function ($$v) {_vm.requestDescription=$$v},expression:"requestDescription"}})],1),_c('div',{staticClass:"d-flex mt-2"},[(_vm.actionType != 2)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.actionType == 0 ? _vm.$i18n.t("Add") : _vm.$i18n.t("Update"))+" ")]):_vm._e(),(_vm.Job != null && _vm.Job.id != null && _vm.actionType == 2)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"type":"button","variant":"outline-danger"},on:{"click":_vm.deleteJob}},[_vm._v(" Cancel ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$i18n.t("Cancel"))+" ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }