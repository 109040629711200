import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function useJobList(refJobListTable) {
  // Use toast

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'id', sortable: true, },
      { key: 'code', label: i18n.t("Code"), sortable: true },
      { key: 'device', label: i18n.t("Device"), sortable: false },
      { key: 'priority', label: i18n.t("Priority"), sortable: false },
      { key: 'description', label: i18n.t("Description"), sortable: false },
      { key: 'product.productCode', label: i18n.t("Product"), sortable: false },
      { key: 'mould.code', label: i18n.t("Mould Code"), sortable: false },
      { key: 'requesterUser', label: i18n.t("Requester User"), sortable: false },
      { key: 'responsibleUser.name', label: i18n.t("Responsible Name"), sortable: false },
      { key: 'status', label: i18n.t("Status"), sortable: false },
      { key: 'targetFinishDate', label: i18n.t("Target FinishDate"), sortable: false },
      { key: 'action', label: i18n.t("Action"), thClass: "text-center", sortable: false },
    ]
  })
  const perPage = ref(10)
  const totalJobs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const rows = ref([])
  const search = ref('')
  // const workcenters = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refJobListTable.value ? refJobListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value,
      of: totalJobs.value,
    }
  })
  /*
    watch([currentPage, perPage, search], () => {
      refetchData()
    })
  */
  function GetDataColectionJobs() {

    return new Promise((resolve, reject) => {
      store.dispatch('pamis-job/fetchJobs')
        .then(response => {
          if (response.data != null && response.data.length > 0) {
            rows.value = response.data
            totalJobs.value = rows.value.length
            rows.value.forEach(e => {
              e.device = `${e.device.name} (${e.device.id})`;
            })
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch(() => {
        })
    })
  }
  function GetJobFieldsData() {
    return store.dispatch('pamis-job/fetchJobFields')
  }
  async function GetData() {
    await GetJobFieldsData()
    await GetDataColectionJobs()
  }
  GetData()
  return {
    GetData,
    rows,
    tableColumns,
    perPage,
    currentPage,
    search,
    totalJobs,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refJobListTable,
  }
}
