<template>
  <div>
    <job-add-new
      :is-add-new-job-sidebar-active.sync="isAddNewJobSidebarActive"
      :selected-job="selectedJob"
      :action-type="actionType"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col
            cols="4"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $i18n.t("Show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $i18n.t("Entries") }}</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                class="d-inline-block mr-1"
                :placeholder="$t('SearchPlaceholder')"
              />
              <b-button variant="primary" @click="newJob">
                <span class="text-nowrap">{{ $t("Add Task") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refJobListTable"
        class="position-relative"
        :items="rows"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="search"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(responsibleUser.name)="data">
          {{
            `${data.item.responsibleUser.name} ${data.item.responsibleUser.surName}`
          }}
        </template>
        <template #cell(targetFinishDate)="data">
          {{ format_date(data.item.targetFinishDate) }}
        </template>
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status == 'Canceled'"
            class="status-badge"
            pill
            variant="light-danger"
          >
            {{ data.item.status }}
          </b-badge>
          <b-badge
            v-else-if="data.item.status == 'Ongoing'"
            class="status-badge"
            pill
            variant="light-warning"
          >
            {{ data.item.status }}
          </b-badge>
          <b-badge
            v-else-if="data.item.status == 'Onhold'"
            class="status-badge"
            pill
            variant="light-secondary"
          >
            {{ data.item.status }}
          </b-badge>
          <b-badge
            v-if="data.item.status == 'Planned'"
            class="status-badge"
            pill
            variant="light-info"
          >
            {{ data.item.status }}
          </b-badge>
          <b-badge
            v-if="data.item.status == 'Done'"
            class="status-badge"
            pill
            variant="light-success"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <template #cell(action)="data">
          <feather-icon
            :id="`invoice-row-${data.item.id}-edit-icon`"
            icon="Edit3Icon"
            size="16"
            class="cursor-pointer mx-50"
            @click="changeJob(data.item, 1)"
          />

          <feather-icon
            :id="`invoice-row-${data.item.id}-sendmessage-icon`"
            icon="MessageSquareIcon"
            size="16"
            class="cursor-pointer mx-50"
            @click="comment(data.item)"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-trash-icon`"
            icon="XIcon"
            size="16"
            class="cursor-pointer mx-50"
            @click="cancelJobConfirm(data.item)"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalJobs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal
        id="modaljobComment"
        ref="refjobComment"
        cancel-variant="outline-secondary"
        cancel-title="Close"
        centered
        :title="$t('Send Comment')"
        size="xl"
        no-close-on-backdrop
      >
        <template>
          <job-comment-list :job-id="jobId" />
        </template>
        <template #modal-footer>
          <b-button variant="outline-secondary" @click="closeCommentModal">{{
            $t("Close")
          }}</b-button>
        </template>
      </b-modal>
      <b-modal
        id="modalCancelJob"
        ref="refCancelJob"
        cancel-variant="outline-secondary"
        cancel-title="Close"
        centered
        title="Please confirm"
        size="md"
        no-close-on-backdrop
        @ok="cancelJob"
      >
        <span>
          This task's status will be changed to canceled. Are you sure?
        </span>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BButton,
  BForm,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormTextarea,
  BFormText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import {
  inject,
  toRefs,
  watch,
  ref,
  onUnmounted,
  computed,
} from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import usejobList from "./useJobList";
import jobStoreModule from "../taskManagementStoreModule";
import JobAddNew from "./JobAddEdit.vue";
import JobCommentList from "./JobComment/JobCommentList.vue";
// import DateFilter from '../components/DateFilter.vue'

export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    JobAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BModal,
    VBModal,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    // DateFilter,
    BForm,
    BTable,
    BFormGroup,
    BFormTextarea,
    BFormText,
    JobCommentList,
  },
  setup() {
    const PAMIS_MODULE_NAME = "pamis-job";
    const refJobListTable = ref(null);
    const refjobComment = ref(null);
    const refCancelJob = ref(null);
    const selectedJob = ref(null);
    const id = ref(0);
    const actionType = ref(0);
    const isCommentActive = ref(false);
    const isAddNewJobSidebarActive = ref(false);
    const jobId = ref(0);
    // Register module
    if (!store.hasModule(PAMIS_MODULE_NAME))
      store.registerModule(PAMIS_MODULE_NAME, jobStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAMIS_MODULE_NAME))
        store.unregisterModule(PAMIS_MODULE_NAME);
    });
    const datefilterId = computed(
      () => store.getters["pamis-job/GetDateFilterId"]
    );
    const {
      GetData,
      rows,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      search,
    } = usejobList(refJobListTable);
    const refetchData = () => {
      GetData();
    };

    const changeJob = (e, actType) => {
      actionType.value = actType;
      selectedJob.value = e;
      isAddNewJobSidebarActive.value = true;
    };

    const newJob = () => {
      selectedJob.value = {
        Id: null,
        name: null,
        code: null,
        jobTypeID: null,
        workcenterID: null,
        isActive: true,
      };
      actionType.value = 0;
      isAddNewJobSidebarActive.value = true;
    };
    function cancelJob() {
      store.dispatch("pamis-job/CancelJob", jobId.value).then(() => {
        refetchData();
      });
    }
    const cancelJobConfirm = (data) => {
      jobId.value = data.id;
      refCancelJob.value.show();
    };
    const comment = (data) => {
      jobId.value = data.id;
      refjobComment.value.show();
    };
    const closeCommentModal = () => {
      refjobComment.value.hide();
    };
    const format_date = (value) => {
      if (value) {
        const myDate = new Date("0001-01-01T00:00:00Z");
        const dateItem = moment(value);

        if (dateItem > myDate) {
          return moment(value).locale("en").format("LLL");
        }
      }
    };
    watch(isAddNewJobSidebarActive, (val) => {
      if (val == false && actionType.value == 1) {
        selectedJob.value = null;
      }
    });

    return {
      id,
      isCommentActive,
      actionType,
      newJob,
      selectedJob,
      changeJob,
      cancelJob,
      cancelJobConfirm,
      comment,
      closeCommentModal,
      format_date,
      isAddNewJobSidebarActive,
      jobId,
      rows,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refJobListTable,
      refjobComment,
      refCancelJob,
      refetchData,
      search,
      // Filter
      avatarText,
      datefilterId,
    };
  },
};
</script>

<style lang="scss" scoped>
table.text-center * {
  text-align: center;
}
.status-badge {
  margin-left: -10px;
  font-size: 13px;
}
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
